import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters([
            "userName", "userMasterCallerNumber", "userRemainSMS", "userRemainLMS", "userRemainMMS",
            "userAddressGroupCount", "userAddressEntryCount", "userAddressDenyCount"
        ]),
    },
    methods: {
        ...mapActions(["addAlert", "doUserLogin", "doLogOut", "goMainPage"]),
        nav(target, subPage) {
            this.goMainPage({ pageName: target, subPage: subPage }).then();
        }
    }
}