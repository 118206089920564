import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import alerts from "./modules/alerts"
import user from "./modules/userModule"
import admin from "./modules/adminModule"
import logger from "@/plugins/logger";
import createPersistedState from 'vuex-persistedstate'//새로고침 데이터 유지용 추가

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [//새로고침 데이터 유지용 추가
    createPersistedState()
  ],
  modules: {
    alerts,
    user,
    admin
  },
  state: {
    pageName: "none",
    subName: "none",
    sessionToken: null,
    subPage : "SmsSendComp",
    popupState : {
      isPopupNotOpen: false,
      dueDate: null
    },
  },
  getters: {
    getSessionToken: (state) =>{
      return state.sessionToken
    },
    pageName: (state) => {
      return state.pageName
    },
    subName: (state) => {
      return state.subName
    },
    subPage:(state)=>{
      return state.subPage
    },
    getPopupState: (state) => {
      return state.popupState
    },

  },
  mutations: {
    mainPath (state, pageName) {
      state.pageName = pageName
    },
    subPath (state, subName) {
      state.subName = subName
    },
    sessionToken ( state,token){
      state.sessionToken = token;
    },
    setSubPage(state,subPage){
      state.subPage = subPage
    },
    setPopupState(state, payload) {
      state.popupState.isPopupNotOpen = payload.isPopupNotOpen;
      state.popupState.dueDate = payload.dueDate;
    },
  },
  actions: {
    async setSessionToken(context,{token}){
      context.commit('sessionToken',token);
    },
    async goMainPage (context, { pageName, subPage }) {
      logger.debug(pageName, subPage);

      context.commit('mainPath', pageName);
      if (subPage) {
        // let before = this.getters.subName;

        // if(before && before !== subPage) {
          context.commit('subPath', subPage);
          await router.push({ name: pageName, params: { subPage: subPage } })
        // }
        // else{
        //   logger.error("duplicate location!");
        // }
      } else {
        context.commit('subPath', 'none');
        await router.push({ name: pageName })
      }
    },
    setSubPage(context,subPage){
      context.commit('setSubPage',subPage)
    }
  }
})
