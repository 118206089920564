<template>
  <div class="dataTables_paginate paging_simple_numbers" v-if="pager.hasRecord()" id="addrList_paginate">

    <span>

        <a :class="{disabled:pager.isFirst()}" @click="pager.setFirstPage()">«</a>


        <a @click="pager.setPrevPage()" :class="{disabled:pager.isFirst()}">&lt;</a>

      
        <a @click="pager.setPage(elm)" v-for="elm in pager.getPages()" v-bind:key="elm" :class="{'on' : pager.isCurrentPage(elm)}">{{elm}}</a>
      
 
        <a @click="pager.setNextPage()" :class="{disabled:pager.isLast()}">&gt;</a>
  

        <a @click="pager.setLastPage()" :class="{disabled:pager.isLast()}">»</a>


        <a v-if="pager.hasMoreRecord()" @click="pager.patchNextBlock()">More</a>

    </span>
  </div>
</template>

<script>
import Pager from "@/classes/Pager.js"

export default {
  name: "PagerPaging",
  props : {
    pager : Pager
  },
  data: () => ({
    loading: {
      width: 200,
      height: 200,
      color: "#874ec1",
      backgroundColor: "#ebd4f2",
    },
  }),
  computed: {
  },
  methods: {
  },
};
</script>
