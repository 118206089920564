import {mapMutations} from 'vuex'

let SsantalkMixin = {
  mounted() {
    console.log(this.$store.getters.getSessionToken)
  },
  computed: {
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setBalance']),
    async setBalanceInfo() { //환불 잔액 조회
      // console.log('SET!!!!!!!!!!',this.$store.getters.getSessionToken)
      if(this.$store.getters.getSessionToken) { // session 이 있는 경우에만 
        try {
          const res = await this.$axios.get("/api/user/account/check-balance")
          if (res.data.success) {
            let balanceInfo = {
              balance : res.data.chargeInfo.balance,
              pointBalance : res.data.chargeInfo.pointBalance,
              remainSMS : res.data.chargeInfo.remainSMS,
              remainLMS : res.data.chargeInfo.remainLMS,
              remainMMS : res.data.chargeInfo.remainMMS,
              afterPaymentInfo: res.data.afterPaymentInfo, 
              userPaymentType: res.data.userPaymentType,

            }
            this.setBalance(balanceInfo);
          } else{
            alert('오류가 발생했습니다. 에러코드: ', res.data.code)
          }
        } catch (err) {
          if(err.response.status == '403') {
  
            //this.$route.replace({name: 'MainPage'})
          } else {
            alert('오류가 발생했습니다.')
            console.error(err)
          }
        }
      }else{ 
        // console.log('///////////////////////////////////////// NULL')
        this.$router.replace({name: 'MainPage'})
      } 
      
    },
  }
}
export default SsantalkMixin
  