import moment from "moment";

moment.locale('ko');

const padZeros = function (input, maxLength) {
  if (maxLength === void 0) { maxLength = 0; }
  return ("0000" + input).slice(-maxLength);
};

const defaultConfig = {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
  ],
  dayOfWeekNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
};

export default {
  capitalize (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  formatNumber(source){
    return source;
  },
  formatDate(source, format, config) {
    let input = new Date(source);

    if (format === void 0) { format = 'YYYY-MM-DD HH:mm:ss'; }
    if (config === void 0) { config = {}; }

    config = Object.assign({}, defaultConfig, config);

    const year = input.getFullYear();
    const month = input.getMonth() + 1;
    const date = input.getDate();
    const hours24 = input.getHours();
    const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
    const minutes = input.getMinutes();
    const seconds = input.getSeconds();
    const weekday = input.getDay();
    return format
      // Normalize tokens
      .replace('YYYY', '%01%')
      .replace('YY', '%02%')
      .replace('MMMM', '%03%')
      .replace('MMM', '%04%')
      .replace('MM', '%05%')
      .replace('M', '%06%')
      .replace('DD', '%07%')
      .replace('D', '%08%')
      .replace('HH', '%09%')
      .replace('H', '%10%')
      .replace('hh', '%11%')
      .replace('h', '%12%')
      .replace('mm', '%13%')
      .replace('m', '%14%')
      .replace('ss', '%15%')
      .replace('s', '%16%')
      .replace('A', '%17%')
      .replace('a', '%18%')
      .replace('dddd', '%19%')
      .replace('dd', '%20%')
      .replace('d', '%21%')
      // Insert values
      .replace('%01%', padZeros(year, 4))
      .replace('%02%', padZeros(year % 100, 2))
      .replace('%03%', config.monthNames[month - 1])
      .replace('%04%', config.monthNamesShort[month - 1])
      .replace('%05%', padZeros(month, 2))
      .replace('%06%', "" + month)
      .replace('%07%', padZeros(date, 2))
      .replace('%08%', "" + date)
      .replace('%09%', padZeros(hours24, 2))
      .replace('%10%', "" + hours24)
      .replace('%11%', padZeros(hours12, 2))
      .replace('%12%', "" + hours12)
      .replace('%13%', padZeros(minutes, 2))
      .replace('%14%', "" + minutes)
      .replace('%15%', padZeros(seconds, 2))
      .replace('%16%', "" + seconds)
      .replace('%17%', hours24 < 12 ? 'AM' : 'PM')
      .replace('%18%', hours24 < 12 ? 'am' : 'pm')
      .replace('%19%', config.dayOfWeekNames[weekday])
      .replace('%20%', config.dayOfWeekNamesShort[weekday])
      .replace('%21%', "" + weekday);
  },
  money(val) { // 천단위 콤마
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  timeFormat(date) {
    if (date == '') {
      return "";
    } else return moment(date).format('YYYY-MM-DD');
    
  },
  hourFormat(date){
    if (date == '') {
      return "";
    } else return moment(date).format('YYYY-MM-DD HH:mm');
  },
  
  numberComma(num) {
    if (!num) {return 0} else {
      return Number(num).toLocaleString()
    }
  },

  phoneDash(num) {
    return String(num).replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--",
      "-")
  },

  phoneNumber(phoneNumber) { // 전화번호 - 자동 넣기
    if(!phoneNumber) return phoneNumber

    phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
    let tmp = ''
    if( phoneNumber.length < 4){
        return phoneNumber;
    }
    else if(phoneNumber.length < 7)
    {
        tmp += phoneNumber.substr(0, 3);
        tmp += '-';
        tmp += phoneNumber.substr(3);
        return tmp;
    }
    else if(phoneNumber.length == 8)
    {
        tmp += phoneNumber.substr(0, 4);
        tmp += '-';
        tmp += phoneNumber.substr(4);
        return tmp;
    }
    else if(phoneNumber.length < 10)
    {
        if(phoneNumber.substr(0, 2) =='02') { //02-123-5678
            tmp += phoneNumber.substr(0, 2);
            tmp += '-';
            tmp += phoneNumber.substr(2, 3);
            tmp += '-';
            tmp += phoneNumber.substr(5);
            return tmp;
        }
    }
    else if(phoneNumber.length < 11)
    {
        if(phoneNumber.substr(0, 2) =='02') { //02-1234-5678
            tmp += phoneNumber.substr(0, 2);
            tmp += '-';
            tmp += phoneNumber.substr(2, 4);
            tmp += '-';
            tmp += phoneNumber.substr(6);
            return tmp;
        } else {                        //010-123-4567
            tmp += phoneNumber.substr(0, 3);
            tmp += '-';
            tmp += phoneNumber.substr(3, 3);
            tmp += '-';
            tmp += phoneNumber.substr(6);
            return tmp;
        }
    }
    else { //010-1234-5678
        tmp += phoneNumber.substr(0, 3);
        tmp += '-';
        tmp += phoneNumber.substr(3, 4);
        tmp += '-';
        tmp += phoneNumber.substr(7);
        return tmp;
    }
  }
}
