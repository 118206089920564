import {
  loginAsUser,
  accountAsUser
} from '@/api/login';
import {
  AUTH_USER_STORAGE
} from '@/constants';
import logger from "@/plugins/logger";
import request from "@/plugins/http";

const userModule = {
  state: {
    failCount: 0,
    balance: 0,
    pointBalance:0,
    account: {
      userPaymentType: "",
      loginId: "",
      userName: "",
      phoneNumber: "",
      remainSMS: 0,
      remainLMS: 0,
      remainMMS: 0,
      masterCallerName: null,
      masterCallerNumber: null,
      callerNumber: [],
      addressGroupCount: 0,
      addressEntryCount: 0,
      addressDenyCount: 0,
      hasCorp: false,
      userCorp: {
        userCorpId: null,
        corpName: null,
        corpRgstNmbr: null,
        corpZoneCode: null,
        corpRoadAddr: null,
        corpRemainAddr: null,
        corpKind: null,
        corpClass: null,
        corpContactNumber: null,
        corpContactMail: null,
        corpCert: null,
        corpTaxInvoiceFlag: false,
        rgstDate: null,
        mngrDate: null,
      },
      afterPaymentInfo: {},
    },
    msgContents: "",
  },

  getters: {
    userName: (state) => state.account.userName,
    userFailCount: (state) => state.failCount,
    userMasterCallerName: (state) => state.account.masterCallerName,
    userMasterCallerNumber: (state) => state.account.masterCallerNumber,
    hasCorp: (state) => state.account.hasCorp,
    userCallerNumber: (state) => state.account.callerNumber,
    corpName : (state) => state.account.userCorp.corpName,
    userPaymentType: (state) => state.account.userPaymentType,
    userAfterPaymentInfo: (state)=> state.afterPaymentInfo,
    userBalance: (state) => state.balance,
    userPoint: (state) => state.pointBalance,
    userRemainSMS: (state) => state.account.remainSMS,
    userRemainLMS: (state) => state.account.remainLMS,
    userRemainMMS: (state) => state.account.remainMMS,
    userAddressGroupCount: (state) => state.account.addressGroupCount,
    userAddressEntryCount: (state) => state.account.addressEntryCount,
    userAddressDenyCount: (state) => state.account.addressDenyCount,
    getMsgContents : (state) => state.msgContents,
    getPaidType : (state) => {
      console.log('getPaidType from userModule',state)
      return state.account.isPostPayment
    },
    getAfterPaymentInfo: (state) => state.afterPaymentInfo
  },

  mutations: {
    SET_USER_INFO(state, payload) {
      logger.debug("set user info : " + JSON.stringify(payload));

      state.failCount = 0;
      state.account = payload;

      if (payload.callerNumber) {
        let masters = payload.callerNumber.filter(elm => elm.masterFlag);

        state.account.masterCallerName = masters.length > 0 ? masters[0].callerName : null;
        state.account.masterCallerNumber = masters.length > 0 ? masters[0].phoneNumber : null;
      }

      /* 임시 추가 부분  */
      state.account.isPostPayment = false

      if (payload.userCorp) {
        if (payload.userCorp.corpAuditStatus === "S") {
          state.account.hasCorp = true;
        } else {
          state.account.hasCorp = false;
          state.userCorp = {};
        }
      } else {
        state.account.hasCorp = false;
        state.userCorp = {};
      }

      logger.debug("save storage", payload);

      localStorage.setItem(AUTH_USER_STORAGE, JSON.stringify(payload));
    },
    CLEAR_USER_INFO(state) {
      state.failCount = 0;
      state.account = {};

      localStorage.removeItem(AUTH_USER_STORAGE);
    },
    addFailCount(state) {
      state.failCount += state.failCount;
    },
    setBalance(state, payload) {
      console.log(payload)
      state.account.userPaymentType = payload.userPaymentType;
      state.account.remainSMS = payload.remainSMS;
      state.account.remainLMS = payload.remainLMS;
      state.account.remainMMS = payload.remainMMS;
      state.balance = payload.balance;
      state.pointBalance = payload.pointBalance
      state.afterPaymentInfo = payload.afterPaymentInfo;
    },
    setMsgContents(state, payload) {
      state.msgContents = payload;
    }
  },
  actions: {
    async doReLogin(context, payload) {
      try {
        logger.debug("normal user login from local storage")
        // validate login
        await context.commit('SET_USER_INFO', payload);
        // navigate user main
        await context.dispatch('goMainPage', {
          pageName: "MemberDashboard"
        });
      } catch (err) {
        logger.warn('[vuex.user] doReLogin', err);
        return Promise.reject(err);
      }
    },
    // Login user
    async doUserLogin(context, payload) {
      try {
        const response = await loginAsUser(payload.id, payload.password);
        logger.debug('[vuex.user] loginByUserId', payload, response);
        if (!response.success || response.failCount >= 5) {
          return response
        } else {
          // validate login
          if (!response['token']) {
            logger.debug("token null login fail");
            await context.commit('addFailCount');
          }

          logger.debug("token received");
          await context.dispatch('setSessionToken', {
            token: response['token']
          });

          const accountInfo = await accountAsUser();
          await context.commit('setMsgContents','');
          await context.commit('SET_USER_INFO', accountInfo);

          // navigate user main
          await context.dispatch('goMainPage', {
            pageName: "MemberDashboard"
          });
        }


      } catch (err) {
        console.error(err)
        logger.warn('[vuex.user] loginByUserId', err);
        return Promise.reject(err);
      }
    },
    async doLogOut(context) {
      try {
        // if(await context.getters.getMsgContents !== ''){
        //   const confirm = window.confirm("작성중이던 내용이 있습니다. 정말 화면을 새로고침하시겠습니까?")
        //   if(!confirm){
        //     return;
        //   } else {
        //     await context.commit('setMsgContents','');
        //   }
        // }
        logger.debug('[vuex.user] LogOut');
        await context.dispatch('goMainPage', {
          pageName: "Intro"
        });
        const response = await request('/auth/logout', 'get');
        console.log('response :', response);
        if(response.error){
          alert("로그아웃 실패");
          return;
        }
        console.log("logout success");
        await context.commit('CLEAR_USER_INFO', {});
        
      } catch (err) {
        logger.warn('[vuex.user] LogOut', err);
      }
    },
  },
};

export default userModule;
