<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  })
}
</script>
<style>
@import url('../public/css/reset.css');
@import url('../public/css/common.css');

</style>

