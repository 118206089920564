<template>
  <transition name="modal">
    <div class="pop_bg" style="z-index:999"  @click.self.prevent="closeModal()">
      <div v-bind:class="{'pop_md': modalSizeUp}" class="pop pop_sm pop_group_set"
      style="z-index:1000">
        <div class="pop_head">
          <slot name="head" />
          <a class="btn_pop_close" @click="closeModal()">닫기</a>
        </div>
        <div class="pop_body">
          <slot name="body" />
        </div>
        <div class="pop_footer">
          <button class="bt-md-ra" @click="actionModal()" v-if="actionName">{{ actionName }}</button>
          <button class="btL-md-ra" @click="closeModal()" v-if="closeName">{{ closeName }}</button>
         
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import ModalHandler from "@/classes/ModalHandler";

  export default {
    name: "ActionPopup",
    props: {
      title: {
        type: String
      },
      dialog: {
        type: ModalHandler
      },
      actionName: {
        type: String
      },
      closeName: {
        type: String
      },
      modalSizeUp: {
        type: String
      },
    },
    data: () => ({}),
    computed: {},
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      actionModal() {
        this.$emit('action');
      },
    },
  };

</script>
