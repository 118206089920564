<template>
  <div class="nav">
    <li v-for="navList in navList" v-bind:key="navList.id"
        v-bind:class="{ active: navList.status}">
      <a v-bind:class="navList.aClass" @click="nav(navList.title)">{{ navList.name }}</a>
      <ul v-if="navList.child" v-show="navList.status">
        <li v-bind:class="{ active: $route.name == navList.child[0].title}" @click="nav(navList.child[0].title)" > {{navList.child[0].name}}</li>
        <li v-bind:class="{ active: $route.name == navList.child[1].title}" @click="nav(navList.child[1].title)" > {{navList.child[1].name}}</li>
      </ul>
    </li>
    <!-- <li>
      <a class="" @click="logOn">LOG-ON</a>
    </li>
    <li>
      <a class="" @click="logOff">LOG-OFF</a>
    </li> -->

    <!-- <li v-bind:class="{ active: isMemberDashboard }">
      <a class="nav_dashboard" @click="nav('MemberDashboard')">대시보드</a>
    </li>
    <li v-bind:class="{ active: isMemberSmsSend }">
      <a class="nav_sms" @click="nav('MemberSmsSend')">문자 보내기</a>
    </li>
    <li v-bind:class="{ active: isMemberAddressEntry }">
      <a class="nav_addr" @click="nav('MemberAddressEntry')">주소록</a>
    </li>
    <li v-bind:class="{ active: isMemberPayment }">
      <a class="nav_pay" @click="nav('MemberPayment')">충전하기</a>
    </li>
    <li v-bind:class="{ active: isMemberSendReport }">
      <a class="nav_report" @click="nav('MemberSendReport')">발송결과</a>
    </li>
    <li>
      <a class="nav_cs" @click="logOn">LOG-ON</a>
    </li>
    <li>
      <a class="nav_cs" @click="logOff">LOG-OFF</a>
    </li>
    <li v-bind:class="{ active: isMemberAnnounce }">
      <a class="nav_cs" @click="nav('MemberAnnounce')">고객센터</a>
    </li> -->
  </div>
</template>

<script>
// import request from "@/plugins/http";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'MainNavigation',
  components : {
  },
  data() {
    return {
      isActivePopup: false,

      navList: [
        {
          id: 0,
          status: false,
          aClass: "nav_dashboard",
          title: "MemberDashboard",
          name: "대시보드",
        }, {
          id: 1,
          status: false,
          aClass: "nav_sms",
          title: "SmsSend",
          name: "문자 보내기",
          child: [
            {
              title: "SmsSend",
              name: "대량 문자",
            },
            {
              title: "SmsSendMapping",
              name: "매핑 문자 ",
            }
          
          ]
        }, {
          id: 2,
          status: false,
          aClass: "nav_addr",
          title: "MemberAddressEntry",
          name: "주소록",
        }, {
          id: 3,
          status: false,
          aClass: "nav_pay",
          title: "MemberPayment",
          name: "충전하기",
        }, {
          id: 4,
          status: false,
          aClass: "nav_report",
          title: "MemberSendReport",
          name: "발송결과",
          child: [
            {
              title: "MemberSendReport",
              name: "완료 내역",
            },
            {
              title: "MemberSendReportWaiting",
              name: "대기 내역",
            }
          
          ]
        }, {
          id: 5,
          status: false,
          aClass: "nav_api",
          title: "MemberApi",
          name: "API 연동",
        },{
          id: 6,
          status: false,
          aClass: "nav_cs",
          title: "MemberAnnounce",
          name: "고객센터",
        },
      ],
    }
  },
  computed: {

    //   isMemberDashboard(){
    //     const val = this.pageName();

    //     return val === 'MemberDashboard';
    //   },
    //   isMemberSmsSend(){
    //     const val = this.pageName();

    //     return val === 'MemberSmsSend';
    //   },
    //   isMemberAddressEntry(){
    //     const val = this.pageName();

    //     return val === 'MemberAddressEntry';
    //   },
    //   isMemberSendReport(){
    //     const val = this.pageName();

    //     return val === 'MemberSendReport';
    //   },
    //   isMemberPayment(){
    //     const val = this.pageName();

    //     return val === 'MemberPayment';
    //   },
    //   isMemberAnnounce(){
    //     const val = this.pageName();

    //     return val === 'MemberAnnounce';
    //   }

  },
  watch: {
    $route() {
      for (var i = 0; i < this.navList.length; i++) {
        this.navList[i].status = this.navList[i].title === this.$route.name ? true : false
      }
      if(this.$route.name == 'MemberSendReportWaiting') this.navList[4].status = true;
      if(this.$route.name == 'SmsSendMapping') this.navList[1].status = true;

    }
  },
  methods: {
    ...mapGetters(["pageName","subPage"]),
    ...mapActions(["goMainPage","setSubPage"]),
    nav(name) {
      if (name === this.$route.name) {
        if(this.$store.getters.getMsgContents !== ''){
          this.emptyMsg()
        }
      }
      
      this.goMainPage({pageName: name}).catch(() => {
        this.setSubPage('SmsSendComp')
      });
      this.navStatus(name);
    },
    emptyMsg(){
      this.$store.commit('setMsgContents', "");
      this.$router.go()

    },
    navStatus(name) {
      this.navList.map(function (o) {
        o.status = false;
        o.title === name ? o.status = true : false
      })
      for (var i = 0; i < this.navList.length; i++) {
        this.navList[i].status = this.navList[i].title === this.$route.name ? true : false
      }
      if(name == 'MemberSendReportWaiting') this.navList[4].status = true;
      if(name == 'SmsSendMapping') this.navList[1].status = true;
    },
    checkAndSendInstantMessage() {
      alert('보내기')
    },
    // async logOn() {
    //   const result = await request('/monitor/sql-trace-on', 'get');
    //   console.log(result);
    // },
    // async logOff() {
    //   const result = await request('/monitor/sql-trace-off', 'get');
    //   console.log(result);
    // },
  },
  mounted() {
    for (var i = 0; i < this.navList.length; i++) {
      this.navList[i].status = this.navList[i].title === this.$route.name ? true : false
    }
    if(this.$route.name == 'MemberSendReportWaiting') this.navList[4].status = true;
    if(this.$route.name == 'SmsSendMapping') this.navList[1].status = true;

  },
  updated() {


  }
}
</script>

<style>
.nav li > ul > li{
  margin: 0px;
  padding: 8px 8px 8px 30px
}
.nav li > ul > li:last-child{
  margin-bottom: 8px;

}
.nav li > ul > li.active {
  opacity: 1;
  width: 95%;
}
</style>
