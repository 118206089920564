<template>
  <div id="wrapper">
    <div-navi></div-navi>
    <div-header></div-header>
      <div id="content">
          <router-view></router-view>
      </div>
    <div-footer></div-footer>
    <AlertMessage />
  </div>
</template>

<script>
import DivHeader from './HeaderPage.vue'
import DivNavi from './MainNavigation.vue'
import DivFooter from './Footer.vue'
import AlertMessage from '../../components/AlertMessage.vue'
export default {
  name: 'LayoutPage',
  components: {
    DivHeader,
    DivNavi,
    DivFooter,
    AlertMessage
  }
}
</script>


