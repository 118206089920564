<template>
  <div id="wrapper">
    <div id="header">
      <h1><a @click="nav('MainPage')" class="logo">싼- 톡</a></h1>
      <div class="user_info" style="top: 20px;">
        <div class="buy-button">
          <a @click="nav('LoginPage')" class="text-primary mr-3">로그인</a>
          <a @click="nav('SignUpAgree')" class="btn btn-primary">회원가입</a>
        </div>
      </div>

    </div>
    <div class="nav">
      <li v-for="navList in navList" v-bind:key="navList.id" v-bind:class="{ active: navList.status}">
        <a v-bind:class="navList.aClass" @click="loginCheck(navList.name)">{{ navList.name }}</a>
      </li>

    </div>
    <div id="content">
      <router-view />
    </div>
    <div-footer></div-footer>
  </div>
</template>

<script>
  import DivFooter from './Footer.vue'

  export default {
    name: 'LayoutPage',
    components: {
      DivFooter
    },
    data() {
      return {
        navList: [{
          id: 0,
          status: false,
          aClass: "nav_dashboard",
          name: "대시보드",
        }, {
          id: 1,
          status: false,
          aClass: "nav_sms",
          name: "문자 보내기",
        }, {
          id: 2,
          status: false,
          aClass: "nav_addr",
          name: "주소록",
        }, {
          id: 3,
          status: false,
          aClass: "nav_pay",
          name: "충전하기",
        }, {
          id: 4,
          status: false,
          aClass: "nav_report",
          name: "발송결과",
        }, {
          id: 5,
          status: false,
          aClass: "nav_cs",
          name: "고객센터",
        }, ],
      }
    },
    watch:{
      $route() {
        this.navList[5].status = false;
        if(this.$route.name == 'AnonymousAnnounce') this.navList[5].status = true;
        
      }
    },
    methods: {
      loginCheck(name) {
        if (name == '고객센터') {
          this.$router.push({
            name:'AnonymousAnnounce'
          })
        } else {
          if (confirm('로그인 후 사용할 수 있는 페이지 입니다.')) {
            this.$router.replace({
              name: 'LoginPage',
               query: {
            recommendCode : this.$route.params.recommendCode || this.$route.query.recommendCode
          }
            })
          }
        }
      },
      nav(name) {
        this.$router.push({
          name: name,
            query: {
            recommendCode : this.$route.params.recommendCode || this.$route.query.recommendCode
          }
        })
      }
    },
    mounted(){
      if(this.$route.name == 'AnonymousAnnounce') this.navList[5].status = true;
    }

  }

</script>

<style scoped>
  .text-primary {
    color: #2f55d4 !important;
  }

  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
  }

  .btn-primary {
    background-color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
    color: #ffffff !important;
    box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%);
  }

  #wrapper {
    padding-bottom: 0;
    position: unset;
  }

</style>
