/* eslint-disable */
<template>
  <div id="header" v-if="isPage"  @click="closeModal">
    <h1><a @click="nav('MemberDashboard')" class="logo">싼- 톡</a></h1>
    <div class="user_info" v-if="isPostpaid"><!-- 후불 -->
      <dl>
        <dt>{{ userAfterPaymentInfo.month.substring(4,6) }}월 누적 사용금액</dt>
        <dd><span class="balance">{{userAfterPaymentInfo.monthTotalPrice}}</span> 원 &nbsp; /</dd>
      </dl>
      <dl>
        <dt>이용단가
          <select v-model="msgType">
            <option value="smsPrice">짧은문자</option>
            <option value="lmsPrice">긴문자</option>
            <option value="mmsPrice">그림문자</option>
          </select>

        </dt>
        <dd><span class="number">{{userAfterPaymentInfo[msgType]}}</span>원</dd>
      </dl>
    </div><!-- // 후불 -->
    <div class="user_info" v-else>
      <dl>
        <dt>충전금<a @click="nav('MemberPayment')">충전하기</a></dt>
        <dd><span class="balance tooltip" @click="goHistory">{{ Math.floor(userRemainBalance) | numberComma }}
            <div class="tooltip_text" v-if="userRemainBalance !=0">
              {{userRemainBalance | numberComma}}
            </div>
          </span> 원 &nbsp; /</dd>
      </dl>
      <dl>
        <dt>포인트<i class="ico_help" @click="isActiveModal=true"></i></dt>
        <dd><span class="balance tooltip" @click="goHistory">{{ Math.floor(userRemainPoint) | numberComma }}
            <div class="tooltip_text" v-if="userRemainPoint !=0">
              {{userRemainPoint | numberComma}}
            </div>
          </span> 원 &nbsp; /</dd>
      </dl>
      <dl>
        <dt>발송가능 건수
          <select v-model="msgType">
            <option value="smsPrice">짧은문자</option>
            <option value="lmsPrice">긴문자</option>
            <option value="mmsPrice">그림문자</option>
          </select>

        </dt>
        <dd><span class="number">{{ count | numberComma }}</span>건</dd>
      </dl>
    </div>
    <div class="user_menu">
      <button class="mymenu">
        <div class="user_img">

          <!-- <Weather /> -->

          <img src="resources/images/user_temp.png">

        </div>
      </button>
      <div class="mymenu_conts">
        <a @click="nav('MemberEditInfo','MemberEditPassword')">마이페이지</a>
        <a @click="doLogOut()">로그아웃</a>
      </div>
    </div>

    <div class="modal" v-show="isActiveModal">
      <div  class="pop pop_sm" style="position: relative;">
        <a class="btn_pop_close"  @click="isActiveModal=false">닫기</a>
        <div class="pop_body pop_pw_error">

          <h2> 포인트는 <br/> 문자 발송 시에만 사용가능하며,<br> 교환 및 환불이 불가한 금액입니다. </h2>
        </div>
      </div>
    </div>

  </div>
</template>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-GZDMCCV44B"></script>
<script>
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-GZDMCCV44B');

</script>

<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  import SsantalkMixin from "@/mixins/SsantalkMixin";
  import Weather from '../../components/MainWeather.vue';

  const js = require('@/assets/common');
  export default {
    components: {
      Weather,
    },
    name: 'HeaderPage',
    mixins: [js.default, SsantalkMixin],
    data() {
      return {
        msgType: 'smsPrice',
        isPage: false,
        isActiveModal:false,
      }
    },
    watch: {
      $route() {
        this.balanceInfo();
      }
    },
    computed: {
      ...mapGetters(["pageName", "userPaymentType","userBalance","userPoint", "userRemainSMS", "userRemainLMS", "userRemainMMS", "userAfterPaymentInfo"]),
      isPostpaid(){
        if(this.userPaymentType == "AFTER_PAYMENT") return true
        else return false
      },
      count() {
        if (this.msgType == "smsPrice") {
          return this.userRemainSMS
        } else if (this.msgType == "lmsPrice") {
          return this.userRemainLMS
        } else {
          return this.userRemainMMS
        }
      },
      userRemainBalance() {
        return this.userBalance;
      },
      userRemainPoint(){
        return this.userPoint
      }
    },
    methods: {
      ...mapActions(["goMainPage"]),
      nav(name) {
        if (name === this.$route.name) {
          this.$router.go(this.$router.currentRoute) //NavigationDuplicated 처리(새로고침 한 것 처럼..)
        }
        this.goMainPage({
          pageName: name
        });
      },
      goHistory() {
        this.$router.push({
          name: "MemberPayment",
          query: {
            sub: 'useHistory'
          }

        })
      },
      closeModal(e){
        if (e.target.classList.contains('modal')) {
          this.isActiveModal = false
        }
      },
      async balanceInfo() {
        this.isPage = false
        await this.setBalanceInfo().finally(() => {
          this.isPage = true
        });
      }
    },
    mounted() {
      this.balanceInfo();


    },
    updated() {
      // if(this.$route.name !== "Main") {
      //   this.balanceInfo();

      // }
    }
  }

</script>
<style scoped>
  .balance.tooltip {
    cursor: pointer;
  }

  .balance .tooltip_text {
    bottom: unset;
    top: 20px;
    left: -10px
  }


</style>
