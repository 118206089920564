<template>
  <div id="footer">
        <ul class="left">
          <p>주식회사 리브리오 <span class="bar">|</span> 대표 : 이상훈 <span class="bar">|</span> 대표번호 : 1811-0793 <span class="bar">|</span> 이메일 : <a href="mailto:3174mt@livrio.co.kr">3174mt@livrio.co.kr</a></p>
          <p>사업자등록번호 : 648-88-01401 <span class="bar">|</span> 주소 : 서울시 강남구 논현로28길 48, 5층</p>
<!--            <a href="javascript:void(0)" @click="nav('MemberPayment')" class="sns_instar">싼톡 인스타</a>-->
<!--            <a href="" class="sns_facebook">싼톡 페북</a>-->
        </ul>
        <ul class="right">
          <a href="https://www.livrio.co.kr" target="_blank">리브리오 소개</a><span class="bar">|</span>
            <a @click="showPolicy">이용약관</a><span class="bar">|</span>
            <a href="javascript:void(0);" @click="showPrivacy">개인정보처리방침</a>
            <span class="corp_name">© livrio Corp.</span>
        </ul>
        <!--약관 띄우기 -->
        <!-- <ModalView v-if="isModalViewed" >
          <component v-bind:is="modalCompo" @close-modal="isModalViewed = false"></component>
        </ModalView> -->
    </div>

</template>

<script>
// import PolicyPopup from "@/views/login/PolicyPopup.vue";
// import PrivacyPopup from "@/views/login/PrivacyPopup.vue";
// import ModalView from "@/views/components/ModalView.vue";

export default {
  name: 'FooterPage',
  components: {
    // PolicyPopup,
    // PrivacyPopup,
    // ModalView,
  },
  data() {
    return { 
      isModalViewed: false, // 모달 띄우기
      modalCompo: null,  // 모달 콤포넌트
    }
  },
  methods: {
    showPolicy: function () {
      // this.modalCompo = PolicyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name:'PolicyPage'
      })
    },
    showPrivacy: function () {
      // this.modalCompo = PrivacyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name:'PrivacyPage'
      })
    },
  }
}

</script>

<style scoped>

</style>
