import request from "@/plugins/http";
import utilities from '@/plugins/utilities';

export default class Pager {
  totalItems = [];
  filteredItems = [];
  displayItems = [];
  pages = [];
  nextPatchStart = 1;
  currentPage = 1;
  totalPages = 0;
  startPage = 0;
  endPage = 0;
  startIndex = 0;
  endIndex = 0;
  searchCondition = {};
  config = {
    columns: [
      {
        display: "define columns!",
        class: "warning"
      }
    ],
    baseUri: '#',
    filter ($pager) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("no filter for " + $pager);
      }
    },
    itemPerPage: 10,
    pagingSize: 10,
    patchSize: 100,
  };

  constructor ($config) {
    Object.assign(this.config, $config);

    if (this.config.baseUri === '#') {
      alert('baseUri is missing');
    }

    console.log("pager configure", this.config);
  }

  async touch(){
    if(this.totalItems.length > 0) {
      return;
    }

    await this.reload({});
  }

  async loadAll ($condition) {
    await this.reload($condition, 0, -1);
  }

  async reload ($condition, start, size) {
    this.totalItems = [];
    this.filteredItems = [];
    this.displayItems = [];
    this.nextPatchStart = 0;
    this.currentPage = 1;

    this.searchCondition = Object.assign({}, $condition);

    await this.patchNextBlock(start, size);
  }

  async createEntity (entity) {
    // console.log("create entity", JSON.stringify(entity));

    const result = await request(this.config.baseUri, 'post', entity);

    // console.log("!!!!!!!!!create response!!!!!!!", result);

    if(!result.success){
      if(result.code == "424") {
        alert('중복된 그룹명이 있습니다. ')
      }else if(result.code=="425") {
        alert('최대 추가 가능한 그룹 수를 초과하였습니다. 그룹 삭제 후 등록해주세요.')
      }

    } else {
      this.totalItems.unshift(result);

      this.build(1);
  
      return result;
    }

  }

  async deleteEntity (entity) {

    const result = await request(this.config.baseUri + "/" + entity, 'delete', entity);

    console.log("delete response", JSON.stringify(result));
    console.log(this.totalItems);
    const targetIndex = this.totalItems.findIndex(((value) => value['pk'] === entity));

    if (targetIndex >= 0) {
      this.totalItems.splice(targetIndex, 1);
    }

    this.build(this.currentPage);
    //return result;
  }

  async updateEntity ($entity) {
    // console.log("update entity:", $entity);

    const result = await request(this.config.baseUri + "/" + $entity['pk'], 'put', $entity);
    // console.log("update response", JSON.stringify(result));


    if(!result.success){
      if(result.code == "424") {
        alert('중복된 그룹명이 있습니다. ')
      }

    } else {
      const targetIndex = this.totalItems.findIndex(((value) => value['pk'] === $entity['pk']));
      if (targetIndex >= 0) {
        this.totalItems[targetIndex] = utilities.copyObject(result);
      }
  
      this.build(this.currentPage);
  
      
      return result;
    }

  }

  async patchNextBlock (start, size) {
    const patchStart = start || this.nextPatchStart;

    if (patchStart < 0) {
      return;
    }

    const patchSize = size || this.config.patchSize;

    const payload = {
      patchStart: patchStart,
      patchSize: patchSize,
      ...this.searchCondition
    };

    const result = await request(this.config.baseUri, 'patch', payload);

    if (result.success) {
      //console.log("result", result);
      if(result.record) {
        result.record.forEach(item => {
          this.totalItems.push(item);
        }) 
  
      } else { 
        result.list.forEach(item => {
          this.totalItems.push(item);
        }) 
      }
      
      this.nextPatchStart = result.next ? result.nextStart : -1;

      // calculate
      this.build(this.currentPage);
    } else {
      // event broad cast
      // TODO : toast error alert으로 전환이 필요함.
      console.log("patchNextBlock fail:" + JSON.stringify(result));
    }
  }

  movePageIndex (targetIndex) {
    this.currentPage = targetIndex || 1;

    this.startIndex = (this.currentPage - 1) * this.config.itemPerPage;

    this.endIndex = this.startIndex + this.config.itemPerPage - 1;

    this.displayItems = this.filteredItems.slice(this.startIndex, this.endIndex + 1);
  }

  build (targetIndex) {
    // first filter
    let filteredResult = this.config.filter(this);

    this.filteredItems = filteredResult ? filteredResult : this.totalItems;

    // if current page is not set.. initial loading
    let currentPage = targetIndex || 1;
    let itemCount = this.filteredItems.length;
    let itemPerPage = this.config.itemPerPage;
    let pagingSize = this.config.pagingSize;
    let startPage = 0;
    let endPage = 0;
    let totalPages = Math.ceil(itemCount / itemPerPage);
    if (totalPages <= pagingSize) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage + 1 >= totalPages) {
        startPage = totalPages - (pagingSize - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - (pagingSize / 2);
        startPage = startPage <= 0 ? 1 : startPage;
        endPage = (startPage + pagingSize - 1) <= totalPages ? (startPage + pagingSize - 1) : totalPages;
        if (totalPages === endPage) {
          startPage = endPage - pagingSize + 1;
        }
      }
    }
    let startIndex = (currentPage - 1) * itemPerPage;
    let endIndex = startIndex + itemPerPage - 1;
    let index = startPage;
    let pages = [];
    for (; index < endPage + 1; index++) {
      pages.push(index);
    }
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.startPage = startPage;
    this.endPage = endPage;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.pages = pages;
    this.displayItems = this.filteredItems.slice(startIndex, endIndex + 1);
  }

  // ---------------------------------------------------------------------
  // section general setter / getters
  // ---------------------------------------------------------------------

  getColumnDefines () {
    return this.config.columns;
  }

  getNextPatchStart () {
    return this.nextPatchStart;
  }

  getPatchSize () {
    return this.config.patchSize;
  }

  getRecordCount () {
    // console.log("getrecordcount :: "+this.totalItems.length);
    return this.totalItems.length;
  }

  getDisplayItems () {
    return this.displayItems;
  }

  getTotalItems () {
    return this.totalItems;
  }

  isCurrentPage (pageIndex) {
    return pageIndex === this.currentPage;
  }

  getPages () {
    return this.pages;
  }

  hasRecord () {
    return this.totalItems.length > 0;
  }

  hasMoreRecord () {
    return this.nextPatchStart >= 0;
  }

  isFirst () {
    return this.currentPage === 1;
  }

  isLast () {
    return this.currentPage === this.totalPages;
  }


  setPage (pageIndex) {
    this.movePageIndex(pageIndex);
  }

  setFirstPage () {
    this.setPage(1);
  }

  setPrevPage () {
    this.setPage(this.currentPage - 1);
  }

  setNextPage () {
    this.setPage(this.currentPage + 1);
  }

  async setLastPage () {
    this.setPage(this.totalPages);

    // get next block if exist
    if (this.nextPatchStart > 0) {
      console.log("last page.. call next block :" + this.nextPatchStart);
      await this.patchNextBlock();
    }
  }
}
