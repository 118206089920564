export default {
  "/auth/login": {
    token: "my token",
    failCount: 0,
    loginId: "mock",
    userName: "mock-user",
    phoneNumber: "02099998888",
    remainSMS: 123,
    remainLMS: 456,
    remainMMS: 789,
    role: "user",
    callerNumber: [
      { callerName: "master-name", phoneNumber: "09088887777", masterFlag: true },
      { callerName: "sub-name1", phoneNumber: "09088887777", masterFlag: false }
    ],
    addressGroupCount: 2,
    addressEntryCount: 30,
    addressDenyCount: 4,
    userCorp: {
      corpName: "test-corp",
      corpRgstNmbr: "000-3330-98874",
      corpKind: "corp-kind-soft",
      corpClass: "corp-class-A"
    }
  }
};
